import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ReactMarkdown from 'react-markdown';
import Protect from 'react-app-protect';

const ReferencePageTemplate = (props) => {
  if (!props) return null;

  const node_locale = props.pageContext.node_locale;
  const translation = props.pageContext.translation;
  const pageContent = props.pageContext.data;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header.node;

  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;

  return (
    <>
      {typeof window !== 'undefined' && (
        <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
          <Layout
            themeColor="mode--light"
            currentLocation={props.path}
            currentNodeLocale={node_locale}
            footerContent={footerContent}
            headerContent={headerContent}
            translation={translation}
          >
            {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

            {pageContent.contentWithReferenceTable.content && (
              <section className="section">
                <div className="container">
                  <div className="legalTerms">
                    <ReactMarkdown
                      source={pageContent.contentWithReferenceTable.content.childMarkdownRemark.rawMarkdownBody}
                      disallowedTypes={['paragraph']}
                      unwrapDisallowed={true}
                    />
                  </div>
                </div>
              </section>
            )}
            {pageContent.contentWithReferenceTable.reference[0] && (
              <div className="data-table pb-5">
                {pageContent.contentWithReferenceTable.reference[0].rows.map((rows, index) => {
                  return index === 0 ? (
                    <div className="data-table__head">
                      {rows.tableRows.map((row) => {
                        return (
                          <div className="data-table__cell">
                            <ReactMarkdown
                              source={row.cellEntry.childMarkdownRemark.rawMarkdownBody}
                              disallowedTypes={['paragraph']}
                              unwrapDisallowed={true}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="data-table__row">
                      {rows.tableRows.map((row) => {
                        return row.cellEntry ? (
                          <div className="data-table__cell">
                            <ReactMarkdown
                              source={row.cellEntry.childMarkdownRemark.rawMarkdownBody}
                              disallowedTypes={['paragraph']}
                              unwrapDisallowed={true}
                            />
                          </div>
                        ) : row.checkmark ? (
                          <div className="data-table__cell">
                            <svg
                              className="icon icon--sm icon--primary"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
                            </svg>
                          </div>
                        ) : (
                          <div className="data-table__cell">
                            <svg
                              className="icon icon--sm icon--primary"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              ></path>
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </Layout>
        </Protect>
      )}
    </>
  );
};

export default ReferencePageTemplate;
